<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
                  <CButton
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('orderWarehouseReturnFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2"/>
                  </CButton>
            </div>
            <div class="col-9 text-right">
              <CButton 
                class="mr-1"
                color="success" 
                square 
                size="sm" 
                @click="openModalEvent('create','orderWarehouseReturnForm',{},'Add Order Remote Warehouse Return')">
                  Add New
              </CButton>
              <CButton 
                :disabled="selectedWarehouses.length == 0"
                color="warning" 
                square 
                size="sm" 
                @click="prepareShipment">
                  Prepare for Shipment
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="orderWarehouseReturnList"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            @row-clicked="rowClicked"
          >
          <template #select="{item,index}">
            <td style="display: flex; justify-content: center;">
              <CInputCheckbox
                  :checked="item._selected"
                  @update:checked="() => check(item,index)"
                  custom
              />
            </td>
          </template>
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                >
                  <CDropdownItem 
                    color="success"
                    @click="openModalEvent('delete','confirm',item, item.orderId + '  order number.', 'Are you sure you want to delete?')">
                      Remove Order Return
                    </CDropdownItem>
                  <CDropdownItem @click="prepareShipment">Prepare for Shipment</CDropdownItem>
                </CDropdown>
              </td>
            </template>
            <template #createTime="{item}">
              <td> {{ dateFormat(item.createTime) }} </td>
            </template>
          </CDataTable>
          <CPagination
            v-show="pages > 1"
            :pages="pages"
            :active-page.sync="activePage"
          />
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterWarehouseReturn"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>

import moment from 'moment';
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'OrderWarehouseReturn',
  components: {FormModal, ConfirmModal, FilterSidebar},
  data() {
    return {
      selectedWarehouse: {},
      selectedWarehouses:[],
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'orderWarehouseReturnForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      lastItem: {},
      activePage: 1,
      itemsPerPage: 10,
      onlyUnreported: false,
      openSidebar: false,
      filterForm: 'orderAlertLogFilter',
      filterParams: {},
      fields: [
        {key: 'select', label: '', _style: 'min-width:1%'},
        {key: 'orderId', label: 'Order Number'},
        {key: 'sAddressPersonName', label: 'Person Name'},
        {key: 'countryName', label: 'Country'},
        {key: 'returnShippingCompany', label: 'Return Shipping Company'},
        {key: 'createTime', label: 'Date of Return Accept.'},
        {key: 'orderChangeStatus', label: 'Order Change Status Type.'},
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'min-width:1%'
        }
      ],
      filters: [{
        type: 'numeric',
        dataIndex: 'orderId',
        field: 'orderId',
        comparison: 'eq'
      }, {
        type: 'string',
        dataIndex: 'orderChangeStatus',
        field: 'orderChangeStatus',
      }, {
          type: 'date',
          dataIndex: 'startTime',
          field: 'createTime',
          comparison: 'gt'
        },
        {
          type: 'date',
          dataIndex: 'endTime',
          field: 'createTime',
          comparison: 'lt'
        }
      ]
    }
  },
  watch: {
    activePage: function(newVal){
      this.page = newVal
      this.filterWarehouseReturn(this.filterParams)
    }
  },
  computed: {
    orderWarehouseReturnList: function(){
      return this.$store.getters.orderWarehouseReturnList;
    },
    loading(){
      return this.$store.getters.orderLoading;
    },
    pages(){
      return Math.ceil(this.$store.getters.orderWarehouseReturnCount / this.itemsPerPage);
    },
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      if(form == 'orderWarehouseReturnForm'){
        data.validDate = moment().format('yyyy-MM-DD');
        this.$store.commit("warehouseReturnOrderInfo")
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    async filterWarehouseReturn(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      if(!formData)
        formData = new FormData();
      formData.append('page', this.activePage)
      formData.append('start', (this.activePage - 1) * this.itemsPerPage)
      formData.append('limit', this.itemsPerPage)
      formData.append('sort', 'id')
      formData.append('dir', 'desc')

      await this.$store.dispatch('order_warehouseReturn', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },

    rowClicked(item, index, column) {
      this.lastItem = item;
      if (column !== "select") {
        this.selectedWarehouses = [];
        this.selectedWarehouse = {};
        for(var i = 0; i <this.orderWarehouseReturnList.length; i++){
          this.$set(this.orderWarehouseReturnList[i], '_selected', false);
          this.$set(this.orderWarehouseReturnList[i], '_classes', '');
        }
        const val = Boolean(this.orderWarehouseReturnList.find(e => e.id == item.id)._selected);
        this.$set(this.orderWarehouseReturnList.find(e => e.id == item.id), '_selected', !val);
        this.$set(this.orderWarehouseReturnList.find(e => e.id == item.id), '_classes', !val ? 'selected' : '');
        this.orderWarehouseReturnList.map(u => {
          if (u._selected) {
            this.selectedWarehouses.push(u);
          }
        });
        this.selectedWarehouse = this.selectedWarehouses[0];
      } else {
        this.check(item,index);
      }
    },

    async check(item, index) {
      let tmpSelected = []
      this.selectedWarehouse = {};
      if(index > -1){
        const val = Boolean(this.orderWarehouseReturnList[index]._selected)
        this.$set(this.orderWarehouseReturnList[index], '_selected', !val)
        this.$set(this.orderWarehouseReturnList[index], '_classes', !val ? 'selected' : '');
        await this.orderWarehouseReturnList.map( u => {
          if (u._selected) {
           tmpSelected.push(u)
          }
        });
        this.selectedWarehouse = tmpSelected[0];
      }
      this.selectedWarehouses = [...tmpSelected]
    },

    async crud(item, actionType){
      if(actionType == 'create' && Object.keys(this.$store.getters.warehouseReturnOrderInfo).length > 0){
        let data = new FormData();
        data.append('orderId', item.orderNo)
        data.append('returnDate', moment(item.validDate).format('DD.MM.YYYY hh:mm:ss'))
        data.append('returnShippingCompany', item.shippingCompany)

        await this.$store.dispatch('order_warehouseReturnAdd', data)
      }else if(actionType == 'delete'){
        let data = new FormData();
        data.append('id', item.id)
        data.append('orderId', item.orderId)

        await this.$store.dispatch('order_warehouseReturnRemove', data)
      }else {
        const toast = {
          msg: 'You must get order information',
          header: 'Warning',
          color: 'warning'
        }
        this.$store.commit('showToast', toast)
      }

      if(this.$store.getters.orderStatus.success){
        this.filterWarehouseReturn(this.filterParams);
        this.openModalEvent()
      }
    },

    async prepareShipment(){
      await this.$store.dispatch('order_getExcelbyOrderWarehouseReturn',{
        responseType: 'blob',
        orderIds: this.selectedWarehouses.map(t => t.orderId).join(',')
      })
      if(this.$store.getters.orderStatus){
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.orderStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'OrderWarehouseReturns_' + moment(new Date).format('DD/MM/YYYY HH:mm:ss') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    },

    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },
  },
  created() {
    this.filterWarehouseReturn(this.filterParams);
  }
}
</script>
